.site-card-border-less-wrapper {
   padding: 30px;
   background: #ebeff2;
   height: 100%;
}

.card-title {
   // font-weight: bold;
   font-weight: 17px;
   margin-left: 14%;
   opacity: 0.9;
}
.card-content {
   margin-left: 22%;
   font-weight: bold;
   font-size: x-large;
}

.dashboard-header {
   font-weight: 600;
   font-size: 36px;
   opacity: 0.9;   
}


