
 #ContentWrapper  .hide {
    display: none;
}

#ContentWrapper  .orange {
    color: #f7941d;
}

#ContentWrapper  .red {
    color: #9E0000;
}

#ContentWrapper  .header {
    background: #3a0908;
    font-size: 30px;
    color: #fff;
    padding: 20px 10px;
    text-align: center;
}

#ContentWrapper .content {
    padding: 40px 10px;
    font-family: 'Century Gothic',Arial,'Franklin Gothic Medium','Arial Narrow',sans-serif;
}
#ContentWrapper .content-body {
    padding: 35px 35px 10px;
}

#ContentWrapper footer {
    position:fixed;
    margin:15px 0;
    left:0px;
    bottom:0px;
    z-index:-1;
}

#ContentWrapper .btn-primary {
    text-align: center;
    color: #fff;
}

#ContentWrapper .footer-btn-wrapper {
    padding: 40px 35px;
    text-align: center;
}

#ContentWrapper .btn-primary {
    background-color: #003be0;
}

#ContentWrapper .form-content {
    background: #fff;
    -webkit-box-shadow: 0px 11px 81px 8px rgba(0,70,133,1);
    -moz-box-shadow: 0px 11px 81px 8px rgba(0,70,133,1);
    box-shadow: 0px 11px 81px 8px rgba(0,70,133,1);
}

#ContentWrapper .content-intro {
    padding: 20px 10px;
    font-size: 22px;
    background: #003be0;
    color:#fff;
}
#ContentWrapper .content-intro span {
        font-size: 30px;
        margin: 0 15px;
        top:5px;
    }
    #ContentWrapper .content-body .footer {
    padding:20px 0 0 0;
    text-align:center;
}
@media (max-width:560px) {
    #ContentWrapper .content {
        padding: 30px 10px;
    }

    #ContentWrapper .content-intro {
        padding: 20px 10px 10px 10px;
        font-size: 14px;
    }
}