.icon-box {
   width: 48px;
   height: 48px;
   text-align: center;
   background: #F57F01;
   color: #fff;
   border-radius: 0.5rem;
   margin-left: auto;
   line-height: 55px;
   display: flex;
   align-items: center;
   justify-content: center;

   svg {
      width: 1.5rem;
      height: 1.5rem;
   }
}

.number {
   h3 {
      font-weight: 700;
      margin-bottom: 0;
      font-size: 2em;
   }

   span {
      font-weight: 600;
      color: #8c8c8c;
      font-size: 1em;
   }
}
