.lineCharts {
   overflow: hidden;
   .title {
      margin-left: 32px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #eeee;

      .label {
         font-size: 16px;
         font-weight: 600;
      }
      .description {
         font-size: 12px;
         color: #6d6c6c;
      }
   }
}

.radiusdot {
   width: 12px;
   height: 12px;
   margin-right: 8px;
   border-radius: 50%;
   display: inline-block;
}

.legend {
   text-align: right;
   color: #999;
   font-size: 14px;

   li {
      height: 48px;
      line-height: 48px;
      display: inline-block;

      & + li {
         margin-left: 24px;
      }
   }
}

.tooltip {
   background: #fff;
   padding: 20px;
   font-size: 14px;

   .tiptitle {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 8px;
   }

   .tipitem {
      max-height: 32px;
      height: auto;
      //line-height: 32px;
      list-style: none;
      display: flex;
      align-items: center;
      
      .tipitem-content {
         display: flex;
         flex-direction: column;
         box-sizing: content-box;
         .tipitem-label {
            font-weight: 600;            
         }
         .tipitem-value {
            font-size: 13px;
         }
      }
   }
}
