.file-item{
  display: flex;
  border: 1px solid #d1d1d1;
  padding: 10px;
  gap: 10px;
  align-items: center;

  &-img{
    height: 16px;
  }
}