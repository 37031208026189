.card {
   padding: 0.7%;
}

.space {
   width: 100%;
}

.dateType {
   margin-left: '1%';
}

.last-update-date {
   width: 105%;
   color: 'black';
}

.calender {
   display: flex;
   margin-left: 5%;
}

.close-circle {
   color: rgba(0, 0, 0, 0.25);
   cursor: pointer;
}

.search-icon-div {
   border: 1px solid #d9d9d9;
   width: 30px;
   margin-left: -1px;
   font-size: 18px;
}

.search-icon {
   margin: 5px;
   color: #d9d9d9;
}

.table-search-row {
   display: flex;
   justify-content: flex-end;
}

.dateRange-validator {
   border-color: red;
}

.summary {
   background-color: red;
}
.modalSummaryRow {
   text-align-last: justify;
   text-align: justify;
   width: 36rem;
   // font-size: 1.45rem;
   // background-color: red;
   //  padding: 1px;
}

.ant-badge-status-processing {
   background-color: red;
   color: red;
   margin-left: 4px;
}

.affix-button {
   border-radius: 10px !important;
   color: rgb(250, 140, 22) !important;
   margin-left: 2% !important;
}

.affix-button-active {
   @extend .affix-button;
   background-color: antiquewhite !important;
   border-color: antiquewhite !important;
}
.affix-button-disable {
   @extend .affix-button;
   background-color: '' !important;
   border-color: '' !important;
}

.mandatoryAsterix::after {
   display: inline-block;
   margin-left: 4px;
   color: #ff4d4f;
   font-size: 14px;
   font-family: SimSun, sans-serif;
   line-height: 1;
   content: '*';
}
.modalClass {
    font-size: medium;
    color: #f57f01;
    text-align: left;
    margin-Left: 1%;
}
.primaryButton {
    background-color: rgb(250, 140, 22);
    border-color: rgb(250, 140, 22);
    border-radius: 10px;
    color: white
}
.clearfix:before {
    display: table;
    content: " ";
}
:after, :after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.clearfix::after {
    clear: both;
    display: table;
    content: " ";
}
.hide{
    display:none;
}