$background-color: #001529; //#fafafa
$dark-theme: false;
// You can send parameter to classes  - such as collapsable param from js -

.header {
   display: flex;
   width: 100%;
   padding: 0;
   align-items: center;
   background: if($dark-theme, $background-color, #fafafa);
}
.user-infos {
   flex: 1;
}

.menu {
   min-width: 320px;
   background: if($dark-theme, $background-color, #fafafa);
   height: 101%;
   color: if(
      $dark-theme,
      rgba(255, 255, 255, 0.65),
      null
   ); // When it's dark mode. !
   .ant-menu-horizontal {
      & > .ant-menu-submenu {
         float: right;
      }
      border: none;
   }
   position: relative;
   .ant-menu-submenu-title {
      width: 64px;
      height: 64px;
      text-align: center;
      padding-top: 8px;
   }
}

.trigger {
   margin-left: 16px;
   margin-right: 16px;
   align-self: center;
}

.menu-logo {
   background-image: url('../../assets/reg-impact-transp.svg');
   background-repeat: no-repeat;
   background-position: center;
   height: 70px;
   background-size: 100%;
   margin: 10px 0px 20px 15px;
   color: #ffffff;
}

.collapsed-menu-logo {
   background-image: url('../../assets/logo-brown-house-transp.svg');
   background-repeat: no-repeat;
   // background-position: center;
   height: 70px;
   background-size: 100%;
   margin: 10px 0px 20px 1px;
   color: #ffffff;
}

.site-layout-background {
   background: #fff;
}

.content-layout {
   margin: 16px 16px 0;
   background-color: #fff;
   height: '10%';
}

.routing-content {
   /* margin: 16px 16px 0;
   padding: 24px; */
   background: #fff;
}

.header-collapse-icon {
   background: if($dark-theme, $background-color, #fafafa);
   color: if($dark-theme, rgba(255, 255, 255, 0.65), null);
   padding: 0%;
   float: left;
   align-items: center;
   width: 5%;
}

.sider {
   background: if($dark-theme, $background-color, #fafafa);
   color: if($dark-theme, rgba(255, 255, 255, 0.65), null);
   // @media screen and (min-width: 480px) {
   //    #menu-logo {
   //       background-image: url('../../assets/logo-brown-house-transp.svg');
   //    }
   // }
}

.sider-menu {
   background: if($dark-theme, $background-color, #fafafa);
   color: if($dark-theme, rgba(255, 255, 255, 0.65), null);
   width: 100.5%;
   .ant-menu-item-selected {
      background-color: if($dark-theme, #1890ff !important, null);
   }
}

// .sider-menu-item {
//    :after {
//       background-color: #1890ff;
//       color: #1890ff;
//    }
// }

.nav-text {
   color: if($dark-theme, rgba(255, 255, 255, 0.65), null);
}

.sider-menu-icon {
   color: if($dark-theme, rgba(255, 255, 255, 0.65), null);
}

.ant-card-meta-title {
   width: 20ch;
}

.default-menu-text {
   font-weight: 600;
}

.ant-table-container{
   overflow-x: auto;
}