#PageWrapper {
    overflow: hidden;
    height: 885px;
 }
 
#ContentWrapper .first-page {
    margin-top: 0 !important;
 }
 /*.first-page #PageWrapper{
    margin-top: 0 !important;
    height:880px;
 }
 .page-width {
    width: 915px !important;
 }*/
 
 #ContentWrapper li > strong {
    color: #004685;
    font-size: 10.5pt;
 }
 
 #ContentWrapper .date {
    margin: 0;
    margin-bottom: .0001pt;
    text-align: right;
    font-size: 12.0pt;
    font-family: "Times New Roman",serif;
 }
 
 #ContentWrapper .quotetitle {
    margin: 0 0 6pt 0;
    font-size: 14pt;
    font-family: "Arial Narrow",sans-serif;
    color: #9E0000;
    font-weight: bold;
 }
 
 .quotebody {
    margin: 0 0 .0001pt 0;
    font-size: 12.0pt;
    font-family: "Arial Narrow",sans-serif;
    color: #004685;
    font-weight: bold;
 }
 
   #ContentWrapper .quotebody p {
        margin: 0 !important;
    }
 
#ContentWrapper .quotename {
    margin: 8.0pt 0 0 0;
    margin-bottom: .0001pt;
    font-size: 12.0pt;
    font-family: "Arial Narrow",sans-serif;
    color: #029491;
    font-weight: bold;
 }
 
#ContentWrapper .imgcredit {
    margin: 0 !important;
    text-align: center;
    font-size: 10.0pt;
    font-family: "Arial Narrow",sans-serif;
 }
 
#ContentWrapper .tableofcontentbody {
    margin: 3.0pt 0;
    font-size: 10.0pt;
    font-family: "Arial Narrow",sans-serif;
 }
 
    .tableofcontentbody p {
        margin: 0 !important;
        padding: 0 !important;
    }
 
 .arrowup {
    margin: 6.0pt 0;
    font-size: 18.0pt;
    font-family: "Arial Narrow",sans-serif;
    color: #00B050;
 }
 
 .arrowdown {
    margin: 6.0pt 0;
    font-size: 18.0pt;
    font-family: "Arial Narrow",sans-serif;
    color: red;
 }
 
 .pagetitle {
    margin-bottom: 0;
    font-size: 14.0pt;
    font-family: "Arial Narrow",sans-serif;
    color: #004685;
    font-weight: bold;
    width: 100%;
    border-bottom: 1px solid #004685;
    margin: 0;
 }
 
 #ContentWrapper .title {
    margin: 9.0pt 0 1.0pt 0;
    font-size: 12.0pt;
    font-family: "Arial Narrow",sans-serif;
    color: #9E0000;
    font-weight: bold;
 }
 
   #ContentWrapper .title a,#ContentWrapper .title a:visited,#ContentWrapper .title a:active {
        color: #9E0000;
        /*text-decoration: none;*/
        cursor: pointer;
    }
 
       #ContentWrapper .title a:hover {
            color: #fff;
            text-decoration: none;
            background: #540000;
            cursor: pointer;
        }
 
#ContentWrapper .introductionLine {
    margin: 0 0 4.0pt 0;
    font-size: 10.0pt;
    font-family: "Times New Roman",serif;
 }
 
   #ContentWrapper .introductionLine p {
        margin: 0 !important;
    }
 
#ContentWrapper .bulletPoint {
    margin-top: 0 0 .0001pt -20.0pt;
    text-indent: -22.0pt;
    font-size: 10.0pt;
    font-family: "Times New Roman",serif;
 }
 
#ContentWrapper .one-rule {
    padding-left: 0 !important;
 }
 
 p {
    margin: 0;
 }
 
#ContentWrapper .summary {
    margin-left: 28pt !important;
    background-color: transparent !important;
 }
 
   #ContentWrapper .summary ul li,#ContentWrapper .summary ol li,#ContentWrapper .introductionLine ul li,#ContentWrapper .introductionLine ol li {
        margin: 0 0 0 -20px;
        padding: 0 0 0 5px;
        font-size: 10.0pt;
        font-family: "Times New Roman",serif;
        list-style-image: url("https://reg-track.com/RegRoom/Template_files/red-square-bullet.jpg");
        line-height: 1.16 !important;
    }
 
    #ContentWrapper ul, #ContentWrapper ol {
    margin: -4px 0 !important;
 }
 
 #ContentWrapper ul li.subtitle, #ContentWrapper ol li.subtitle/*, #ContentWrapper .summary li[data-timestamp]*/ {
        list-style: none;
        list-style-type: none;
        margin: 8pt 0 0 -38px !important;
        line-height: 16px;
        font-family: 'Times New Roman';
        font-size: 10.5pt;
    }
 
    #ContentWrapper li > strong::before {
    list-style: none;
    list-style-type: none;
 }
 
 #ContentWrapper .page .header {
    position: relative;
    width: auto;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #004685;
    margin-bottom: 0;
 }
 
 #ContentWrapper .page .header.page-width table tr td strong {
        font-size: 24pt;
        font-weight: 700;
    }
 
    #ContentWrapper .headerDate {
    font-size: 18px;
    text-align: right;
    color: #004685;
 }
 
 #ContentWrapper .page .first-section {
    /* margin-top: 25px;*/
    position: relative;
 }
 
 #ContentWrapper .page-number {
    text-align: right;
 }
 
 #ContentWrapper .page table tr td {
    vertical-align: top;
 }
 
 #ContentWrapper .page table tr .left-column {
    padding: 0 12px 0 0;
 }
 
 #ContentWrapper .page table tr .right-column {
    padding: 0 0 0 11px;
 }
 
 #ContentWrapper .first-page table tr td {
    vertical-align: middle !important;
    padding: 10px 5px 0 0;
 }
 
 #ContentWrapper table {
    border-collapse: collapse;
    width: 100%;
    border: 0;
    margin: 0;
 }
 
 #ContentWrapper table tr .watchlist-arrow {
        vertical-align: middle;
        text-align: left;
        width: 17px;
        padding: 0 5px 0 0;
    }
 
 
 
    #ContentWrapper .top-gap {
    height: 100px !important;
 }
 
 #ContentWrapper .saving {
    color: #029491;
 }
 
 #ContentWrapper .text-danger {
    color: #ff6a00;
 }
 
 #ContentWrapper .bodyImage {
    border: 0;
 }
 
 #ContentWrapper #createPDF {
    display: none;
 }
 
 #ContentWrapper .banner-logo,  #ContentWrapper .banner-title, .headerDate {
    padding: 0 !important;
 }
 
 #ContentWrapper td.headerDate {
    vertical-align: bottom !important;
 }
 
 #ContentWrapper .frontpage-subtitle {
    margin: 0;
    padding-bottom: 0;
    padding-top: 20px !important;
    font-size: 14pt;
    font-family: "Arial Narrow",sans-serif;
    color: #9E0000;
    font-weight: bold;
 }
 
 #ContentWrapper .index {
    width: 0 !important;
    height: 0 !important;
    margin: 0 !important;
 }
 
 #ContentWrapper #TableOfContent td, #TableOfContent th {
    padding-left: 0 !important;
    padding-right: 0;
 }
 
 #ContentWrapper #TableOfContent th {
    text-align: left;
 }
 
 #ContentWrapper #TableOfContent tbody tr {
    border-bottom: dotted 1px #004685;
 }
 
 #ContentWrapper .first-rule {
    margin-top: 3.0pt !important;
 }
 
 #ContentWrapper .first-page table tr td.table-content-number {
    white-space: nowrap !important;
    padding-left: 5px !important;
    vertical-align: bottom !important;
    padding-bottom: 0 !important;
 }
 
 #ContentWrapper .table-content-number a {
    text-decoration: none;
 }
 
 #ContentWrapper .fix-right,  #ContentWrapper .fix-left {
    position: fixed;
    right: 0;
    top: 40%;
    margin: 0;
    z-index: 10000;
    text-align: center;
    color: #fff;
 }
 
 @media print {
    #ContentWrapper .page {
        page-break-after: always;
    }
 }
 
 #ContentWrapper .pointer {
    cursor: pointer;
 }
 
 #ContentWrapper .progress-container-center {
    z-index: 999999;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 88px;
    height: 88px;
    margin-top: -44px; /*set to a negative number 1/2 of your height*/
    margin-left: -44px; /*set to a negative number 1/2 of your width*/
    /*border: 1px solid #ccc;
    background-color: #f3f3f3;*/
 }
 
 
 #ContentWrapper #sortableWrapper {
    z-index: 99999;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 388px;
    margin-top: -194px; /*set to a negative number 1/2 of your height*/
    margin-left: -150px; /*set to a negative number 1/2 of your width*/
    /*border: 1px solid #ccc;*/
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 15px;
 }
 
 #ContentWrapper .page,  #ContentWrapper .summary,  #ContentWrapper .first-page, 
 #ContentWrapper .title,  #ContentWrapper .pagetitle,  #ContentWrapper .introductionLine {
    line-height: 1.16 !important;
 }
 
 /************************************ NEW RRR ********************************************/
 #ContentWrapper .article-text {
    letter-spacing: -.01em;
    font-size: 16px;
    line-height: 23px
 }
 #ContentWrapper {
    width: 660px !important;
 }
    #ContentWrapper .country-header {
        font-size: 1.6em;
        color: #004685;
        font-weight: bold;
        width: 100%;
        border-bottom: 2px dotted #004685;
        margin: 0;
        padding: 9px 20px 8px 10px;
        background-color: #ffffff;
        display: block;
        position: relative;
        margin-top: 30px;
    }
 
    #ContentWrapper .summary ul li,
    #ContentWrapper .summary ol li,
    #ContentWrapper .introductionLine ul li,
    #ContentWrapper .introductionLine ol li,
    #ContentWrapper .introductionLine {
        margin: 0 0 2px -20px;
        padding: 8px 0 2px 5px;
        font-size: 16px;
        font-family: 'Trebuchet MS',Tahoma,Verdana,Arial,sans-serif;
        line-height: 1.2 !important;
    }
 
 #ContentWrapper .introductionLine {
    line-height: 1.2 !important;
    font-size: 1.2em;
    margin: 10px 0;
    color:#d1840a;
    padding-left:0px;
 }
 
 #ContentWrapper .title {
    font-size: 18px;
 }
 
    #ContentWrapper .title a:hover {
        padding: 5px !important;
    }
 
 #ContentWrapper .country-header, #ContentWrapper .title, #ContentWrapper li > strong,
 #TableOfContent tbody tr td strong {
    font-family: Georgia, "Century Old Style", "Times New Roman", Times, sans-serif, Utopia;
 }
 
 #ContentWrapper a {
    text-decoration: none !important;
 }
 
 #ContentWrapper li > strong {
    font-size: 17px;
 }
 
 #ContentWrapper {
    position: relative;
    display: block;
    margin: 0px auto 30px auto !important;
    width: 657px !important;
 }
 
    #ContentWrapper .summary li {
        text-align-last: justify;
        text-align: justify;
    }
 
        /*#ContentWrapper .summary .subtitle, #ContentWrapper .summary li[data-timestamp] {
            text-align-last: left !important;
            text-align: justify;
        }*/
    #ContentWrapper .tableofcontentbody {
        font-family: Century, "Century Gothic", Arial, sans-serif;
    }
    #ContentWrapper #TableOfContent {
    background-color: #ffffff;
    padding: 0px 15px 40px 15px;
    font-size: 1.2em;
 }
 
 #ContentWrapper #TableOfContent th {
        font-size: 1.4em;
        padding-bottom: 3px;
        margin-bottom: 5px;
        display: flex;
        border-bottom: 2px dotted #004685;
    }
 
    #ContentWrapper #TableOfContent tbody  tr td {
        padding: 20px 0;
    }
 
    #ContentWrapper #TableOfContent tbody tr td a {
        color: #004685;
        font-size: .94em;
        line-height: 1.6em;
        margin-left: 5px;
    }
 
    #ContentWrapper #TableOfContent tbody tr td a:hover {
            color: #713b3b;
        }
 
        #ContentWrapper #TableOfContent tbody tr td strong a {
            margin: 0 0 5px 0;
            font-size: 1.3em;
            display: block;
        }
 
        #ContentWrapper #TableOfContent tableofcontentbody tbody tr td a {
        padding: 10px 0;
    }
 
    #ContentWrapper #TableOfContent tbody tr td li{
        font-size: 18px;
    }
 #ContentWrapper ul li.subtitle, #ContentWrapper ol li.subtitle/*, #ContentWrapper .summary li[data-timestamp]*/ {
    margin-top: 15px !important;
 }
 #ContentWrapper .title {
    margin-top: 40px;
    font-size: 1.4em;
 }
 .remove-justify {
    text-align-last: left !important;
    text-align: left !important;
 }
 
 .remove-justify-last {
    text-align-last: left !important;
    text-align: justify;
 }

