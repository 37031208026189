@media screen and (max-width: 1080px) {
    #ContentWrapper #TableOfContentFixed, #BackToTop {
        display: none !important;
    }
    #ContentWrapper .is-fixed-content{
        margin-left:auto !important;
        margin-right:auto !important;
    }
}
@media print {
    #ContentWrapper #TableOfContentFixed, #BackToTop {
        display: none !important;
    }
    #ContentWrapper .is-fixed-content {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    #ContentWrapper #LogoBanner {
        position: relative !important;
    }
    #ContentWrapper .content {
        padding-top: 10px !important;
    }
}
#ContentWrapper .content, .form-content {
    width: 750px !important;
    max-width: 750px;
    margin: auto;
}

#ContentWrapper .content {
    padding-top: 130px;
}

#ContentWrapper .content-intro {
    padding-left: 30px;
    background: #540000;
}

#ContentWrapper #PageWrapper {
    height: inherit;
    background-color: #efefef;
}

#ContentWrapper .content-body {
    background-color: #efefef;
    margin-bottom: 100px;
    padding-bottom: 30px;
}

#ContentWrapper .page {
    height: inherit !important;
}

/* Fix the table of content to the left*/

#ContentWrapper #TableOfContentFixed:after {
    content: "";
    display: table;
    clear: both;
}

#ContentWrapper #TableOfContentFixed.is-fixed {
    position: fixed;
    height: 95vh;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0px;
    left: 8px;
    z-index: 99;
    width: 28vw;
    min-width: 220px !important;
    -webkit-overflow-scrolling: touch;
}

    #ContentWrapper #TableOfContentFixed.is-fixed tbody tr td a {
        font-size: .8em
    }

    #ContentWrapper #TableOfContentFixed.is-fixed tbody tr td strong a {
        font-size: inherit;
    }

#ContentWrapper .is-fixed-content {
    margin: 0 auto 0 11vw;
}

#ContentWrapper #BackToTop {
    position: fixed;
    bottom: 50px;
    left: 12vw;
    z-index: 100;
}

#ContentWrapper #TableOfContentFixed {
    background-color: #fff;
    padding: 0 5px 100px 15px;
    z-index: 99;
}

    #ContentWrapper #TableOfContentFixed .TableOfContent {
        padding-bottom: 100px;
        display: block;
        position: relative;
    }

#ContentWrapper .footer {
    display: block;
    padding: 20px !important;
    background-color: #587892;
    color: #ffffff;
    font-size: .75em;
    margin: 20px auto 0px auto;
    letter-spacing: 1px;
}

#ContentWrapper .clearfix:before, #ContentWrapper .clearfix:after, #ContentWrapper .dl-horizontal dd:before,
#ContentWrapper .dl-horizontal dd:after, #ContentWrapper .container:before, #ContentWrapper .container:after,
#ContentWrapper .container-fluid:before, #ContentWrapper .container-fluid:after, #ContentWrapper .row:before,
#ContentWrapper .row:after, #ContentWrapper .form-horizontal #ContentWrapper .form-group:before, #ContentWrapper .form-horizontal
#ContentWrapper .form-group:after, #ContentWrapper .btn-toolbar:before, #ContentWrapper .btn-toolbar:after,
#ContentWrapper .btn-group-vertical > #ContentWrapper .btn-group:before, #ContentWrapper .btn-group-vertical >
#ContentWrapper .btn-group:after, #ContentWrapper .nav:before, #ContentWrapper .nav:after, #ContentWrapper .navbar:before,
#ContentWrapper .navbar:after, #ContentWrapper .navbar-header:before, #ContentWrapper .navbar-header:after,
#ContentWrapper .navbar-collapse:before, #ContentWrapper .navbar-collapse:after, #ContentWrapper .pager:before,
#ContentWrapper .pager:after, #ContentWrapper .panel-body:before, #ContentWrapper .panel-body:after,
#ContentWrapper .modal-footer:before, #ContentWrapper .modal-footer:after {
    display: table;
    content: " ";
}

#ContentWrapper .clearfix:after, #ContentWrapper .dl-horizontal dd:after, #ContentWrapper .container:after, #ContentWrapper .container-fluid:after,
#ContentWrapper .row:after, #ContentWrapper .form-horizontal #ContentWrapper .form-group:after, #ContentWrapper .btn-toolbar:after, #ContentWrapper .btn-group-vertical >
#ContentWrapper .btn-group:after, #ContentWrapper .nav:after, #ContentWrapper .navbar:after, #ContentWrapper .navbar-header:after, #ContentWrapper .navbar-collapse:after,
#ContentWrapper .pager:after, #ContentWrapper .panel-body:after, #ContentWrapper .modal-footer:after {
    clear: both;
}

#ContentWrapper .clearfix:before, #ContentWrapper .clearfix:after, #ContentWrapper .dl-horizontal dd:before, #ContentWrapper .dl-horizontal dd:after,
#ContentWrapper .container:before, #ContentWrapper .container:after, #ContentWrapper .container-fluid:before, #ContentWrapper .container-fluid:after,
#ContentWrapper .row:before, #ContentWrapper .row:after, #ContentWrapper .form-horizontal #ContentWrapper .form-group:before, #ContentWrapper .form-horizontal
#ContentWrapper .form-group:after, #ContentWrapper .btn-toolbar:before, #ContentWrapper .btn-toolbar:after, #ContentWrapper .btn-group-vertical >
#ContentWrapper .btn-group:before, #ContentWrapper .btn-group-vertical > #ContentWrapper .btn-group:after, #ContentWrapper .nav:before, #ContentWrapper .nav:after,
#ContentWrapper .navbar:before, #ContentWrapper .navbar:after, #ContentWrapper .navbar-header:before, #ContentWrapper .navbar-header:after,
#ContentWrapper .navbar-collapse:before, #ContentWrapper .navbar-collapse:after, #ContentWrapper .pager:before, #ContentWrapper .pager:after,
#ContentWrapper .panel-body:before, #ContentWrapper .panel-body:after, #ContentWrapper .modal-footer:before, #ContentWrapper .modal-footer:after {
    display: table;
    content: " ";
}

#ContentWrapper :before, #ContentWrapper :after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#ContentWrapper .right-box-top {
    padding: 0 8px 3px 0 !important;
    margin-top: 5px;
    position: relative;
    font-size: 0.95em;
    font-family: 'Century Gothic',Arial,'Franklin Gothic Medium','Arial Narrow',sans-serif;
    /*border: 1px dotted #fff!important;*/
}

    #ContentWrapper .right-box-top p {
        padding: 3px 0;
    }
#ContentWrapper #TableOfContentFixed .active {
    color: #ffffff;
    background-color: #d5840a;
    padding: 2px;
}