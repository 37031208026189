.ruleRow {
   text-align-last: justify;
   text-align: justify;
   // color: rgba(0, 0, 0, 0.45);
   transition: all 0.3s ease 0s;
   display: block;
}

.ruleRow::before {
   // color: transparent;
   font-size: 1px;
   content: ' ';
   margin-left: -1.3em;
   margin-right: 15px;
   padding: 10px;
   // background-color: rgba(0, 0, 0, 0.45);
   // color: rgba(0, 0, 0, 0.45);
   // -webkit-mask-image: url('../../assets/bullet.svg');
   // -webkit-mask-size: cover;
}
.ruleHeader {
   display: block;
   position: relative;
   margin: 2% 0px 1% 3% !important;
}

.summaryRow ul li {
   text-align-last: justify;
   text-align: justify;
   // font-size: 1.45rem;
   // background-color: red;
   //  padding: 1px;
}

.textAlignLeft {
   text-align-last: left !important;
}

.subHeaderOnsummary {
   text-align-last: left !important;
   display: block;
   margin: 10px 0px 0px 0px !important;
}

.multipleLine {
   text-align-last: left !important;
}

.editable-cell {
   position: relative;
}

.editable-cell-value-wrap {
   padding: 5px 12px;
   cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
   padding: 4px 11px;
   border: 1px solid #d9d9d9;
   border-radius: 2px;
}

.left-col {
   text-align: right;
}

.search-row {
   margin-bottom: 2%;
}

.search-button {
   border-radius: 10px;
   background-color: rgb(250, 140, 22);
   border-color: rgb(250, 140, 22);
   color: white;
}

.clear-button {
   margin-right: 8%;
   border-radius: 10px;
   width: 100;
}

.mandatoryAsterix::after {
   display: inline-block;
   margin-left: 4px;
   color: #ff4d4f;
   font-size: 14px;
   font-family: SimSun, sans-serif;
   line-height: 1;
   content: '*';
}

.mandatory-border {
   border-color: red;
}
