.successMessage {
   color: rgba(0, 0, 0, 0.85);
   font-size: 20px;
   line-height: 1.8;
   text-align: center;
}

.successSubMessage {
   color: rgba(0, 0, 0, 0.45);
   font-size: 14px;
   line-height: 1.6;
   text-align: center;
}

.successIcon {
   font-size: '50px';
   color: '#08c';
   margin-top: '5%';
}

.dashed {
   margin-left: 13%;
   min-width: 50%;
   width: 75%;
}

.mandatoryAsterix::after {
   display: inline-block;
   margin-left: 4px;
   color: #ff4d4f;
   font-size: 14px;
   font-family: SimSun, sans-serif;
   line-height: 1;
   content: '*';
}
