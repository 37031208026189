.root-node {
   fill: red !important;
   stroke: red !important;
}

.root-branch {
   fill: #fa8c16 !important;
   stroke: #fa8c16 !important;
}

.root-leaf {
   fill: #1890ff !important;
   stroke: #1890ff !important;
}
