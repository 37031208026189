.loader-page{
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo{
    width: 200px;
  }
  .loader-icon{
    width: 50px;
  }
}