.newsletter-comment {
	position: relative;
	width: 100%;	
	background-color: #fff;
	padding: 0.75em 1.5em;
	font-size: .95em;
	border-radius: 1rem;
    margin-top: 1.2em;
  box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

.newsletter-comment th{
	padding: 0.75em;
	color:#004685 ;
    font-family: Georgia, "Century Old Style", "Times New Roman", Times, sans-serif, Utopia;
    font-weight: 700;
    font-size: 1.01em;
    white-space:nowrap;
    vertical-align: top;;
}

.newsletter-comment td{
	width: 100%;
    vertical-align: top;
    padding: 0.75em;
}
.newsletter-comment .gap{
    border-bottom: dotted 1px #004685;
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    border-bottom-color: rgb(0, 70, 133);
    position: relative;
    display: flex;
    margin: 5px -15px;
}
